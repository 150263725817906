import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Test Week Starts Today!`}</strong></p>
    <p>{`Deadlifts 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Max Height Box Jumps to a 1RM`}</p>
    <p>{`HS Walk max distance, 2 attempts`}</p>
    <p>{`then, `}<strong parentName="p">{`Regional Event 4`}</strong>{` (light)`}</p>
    <p>{`60ft HS Walk (scaled to 100ft Bear Crawl)`}</p>
    <p>{`10-T2B`}</p>
    <p>{`10-KB Deadlifts (53’s/35’s)`}</p>
    <p>{`60ft HS Walk`}</p>
    <p>{`12-T2B`}</p>
    <p>{`12-KB Deadlifts`}</p>
    <p>{`60ft HS Walk`}</p>
    <p>{`14-T2B`}</p>
    <p>{`14-KB Deadlifts`}</p>
    <p>{`60ft HS Walk`}</p>
    <p>{`16-T2B`}</p>
    <p>{`16-KB Deadlifts`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`On October 28th The Ville will be hosting a fundraising wod for the
family of LMPD Officer Jason Schweitzer who was tragically killed by a
drunk driver in Lexington, Ky.  You can donate and purchase a shirt at
the link below.  CrossFit 222 will show up as the host but you can
choose your shirt size and our location when checking out.  The shirts
will be brought to The Ville for you.  Please sign up soon and show your
support for one of our LMPD officers and his family!  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Event brite
link: `}<a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1"
          }}>{`https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1`}</a></em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  Get your teams together now.
Truegritcomp.wordpress.com.  Workout 1 is posted, WOD 2 to post tonight!
 If you’d like to be on a team but don’t have teammates please email
Daniel.  You can also add your name `}<strong parentName="p">{`**`}</strong>{`to the board in back at the
Ville if you’d like to help judge or volunteer. ***`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2"
      }}>{`https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      